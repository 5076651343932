.leaflet-bar button,
.leaflet-bar button:hover {
  line-height: 24px;
}

@media
(-webkit-min-device-pixel-ratio:2),
(min-resolution:192dpi) {
    .iOS-device .easy-button-button {
        /* background-color: rgb(44, 148, 75); */
        padding-left: 11px !important;

      }   
}
 
